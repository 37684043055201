import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseLockBodyScrollExample } from './use-lock-body-scroll.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "uselockbodyscroll"
    }}>{`useLockBodyScroll`}</h1>
    <p>{`Will lock body and prevent it from being scrolled. Useful when showing a modal
or anything else when you don't want the user to be able to scroll the body.
From `}<a parentName="p" {...{
        "href": "https://usehooks.com/useLockBodyScroll/"
      }}>{`useHooks`}</a>{`.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useLockBodyScroll } from '@fransvilhelm/hooks';

const Modal = ({ children }) => {
  useLockBodyScroll();
  return <div>{children}</div>;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseLockBodyScrollExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseLockBodyScrollExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseLockBodyScrollExample mdxType="UseLockBodyScrollExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useLockBodyScroll`}</inlineCode>{` accepts two parameters.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`lock`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{` false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Wether to apply the lock or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`RefObject\\<HTMLElement`}{`>`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`useRef(document.body)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A ref object pointing to an element to lock scrolling on`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useLockBodyScroll`}</inlineCode>{` returns nothing`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      